<template>
    <div>
        <v-sheet class="dense-inputs">
            <div class="d-flex flex-row align-center">
                <v-btn @click="openProductSelector()">{{ $t('message.addProduct') }}</v-btn>
                <v-btn icon @click="getProductList(); loading.reload = true" :loading="loading.reload" class="ml-3"><v-icon large>refresh</v-icon></v-btn>
                <v-spacer/>
                <ExportTableJson
                    :export-conditions="{}"
                    :export-data="allProducts"
                    :export-fields="headers"
                    :export-source="'supplier-product-table'"
                    class="float-right"
                    style="padding-top: 0px !important;"
                />
            </div>
        </v-sheet>
        <v-overlay
            :value="loading.products"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items-per-page="-1"
            :items="allProducts"
            :group-by="defaultGroupByProperty"
            class="mt-3 appic-table-light specification-table"
            dense
            hide-default-footer
            show-group-by
        >
            <template v-slot:group.header="{ group, groupBy, headers, isOpen, toggle }">
                <td :colspan="headers.length">
                    <div>
                        <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                        {{ getHeader(groupBy) + ': ' + getPropertyValue(group) }}
                    </div>
                </td>
            </template>
            <template v-slot:item.Product.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openProductChanger(item.Product.id, item.Product.product)"><v-icon small class="mr-1" color="black lighten-1">mdi-swap-horizontal-variant</v-icon>{{ $t('message.changeProduct') }}</v-list-item>
                            <v-list-item class="font-sm" @click="copyProduct(item.Product.id)"><v-icon small class="mr-1" color="black lighten-1">mdi-content-copy</v-icon>{{ $t('message.copyProduct') }}</v-list-item>
                            <v-list-item class="font-sm" @click="deleteProduct(item.Product.id, item.Product.product)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteProduct') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:item.Product.species="{ item }">
                <template v-for="property in item.Product.species">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.species && item.Product.species.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.species', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.species') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.grade="{ item }">
                <template v-for="property in item.Product.grade">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.grade && item.Product.grade.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.grade', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.grade') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.origin_certification="{ item }">
                <template v-for="property in item.Product.origin_certification">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.origin_certification && item.Product.origin_certification.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.origin_certification', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.certification') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.market_certification="{ item }">
                <template v-for="property in item.Product.market_certification">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.market_certification && item.Product.market_certification.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.market_certification', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.certification') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.sizes="{ item }">
                <template v-for="property in item.Product.sizes">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.size && item.Product.sizes.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.sizes', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.sizes') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.thickness="{ item }">
                <template v-for="property in item.Product.thickness">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.thickness && item.Product.thickness.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.thickness', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.thickness') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.width="{ item }">
                <template v-for="property in item.Product.width">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.width && item.Product.width.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.width', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.width') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.length="{ item }">
                <template v-for="property in item.Product.length">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.length && item.Product.length.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.length', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.length') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.drying_type="{ item }">
                <template v-for="property in item.Product.drying_type">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.drying_type && item.Product.drying_type.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.drying_type', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.dryingType') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.moisture_content="{ item }">
                <template v-for="property in item.Product.moisture_content">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.moisture_content && item.Product.moisture_content.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.moisture_content', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.moistureContent') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.density="{ item }">
                <template v-for="property in item.Product.density">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.density && item.Product.density.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.density', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.density') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.surface="{ item }">
                <template v-for="property in item.Product.surface">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.surface && item.Product.surface.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.surface', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.surface') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.composition="{ item }">
                <template v-for="property in item.Product.composition">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.composition && item.Product.composition.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.composition', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.composition') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.glue="{ item }">
                <template v-for="property in item.Product.glue">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.glue && item.Product.glue.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.glue', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.glue') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.finishing="{ item }">
                <template v-for="property in item.Product.finishing">
                    <v-chip close close-icon="mdi-close" small @click:close="deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)">{{ property.prop_value }}</v-chip>
                </template>
                <template v-if="item.Product.finishing && item.Product.finishing.length == 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openPropertySelector('Product.finishing', item.Product.id)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addProperty.finishing') }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:item.Product.comments="{ item }">
                <template v-if="item.Product.comments != null">
                    <v-chip close close-icon="mdi-close" small @click:close="deleteCommentsFromProduct(item.Product.id)">{{ item.Product.comments }}</v-chip>
                </template>
                <template v-else>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{...tooltip}" @click="openAddComment(item.Product.id, item.Product.comments)"><v-icon>add</v-icon></v-btn>
                        </template>
                        <span>{{ $t('message.addComments') }}</span>
                    </v-tooltip>
                </template>
            </template>
        </v-data-table>
        <AddProductComments
            :key="addCommentKey"
            :current-comment="selectedComment"
            :product-id="selectedProductId"
            :open-dialog.sync="addComment"
            @property-added="commentAdded"
            @dialog-closed="addCommentClosed"
        />
        <ProductPropertyPicker
            :key="propertySelectorKey"
            :property="selectedProperty"
            :product-id="selectedProductId"
            :open-dialog.sync="propertySelector"
            @property-added="propertyAdded"
            @dialog-closed="propertySelectorClosed"
        />
        <ProductSelector
            :key="productSelectorKey"
            :customer-id="null"
            :supplier-id="supplierId"
            :open-dialog.sync="productSelector"
            @product-added="productAdded"
            @dialog-closed="productSelectorClosed"
        />
        <ChangeProduct
            :customer-id="null"
            :supplier-id="null"
            :product-id="selectedProductId"
            :current-product="selectedProductGroup"
            :open-dialog.sync="changeProduct"
            @product-updated="productUpdated"
            @dialog-closed="changeProductClosed"
        />
    </div>
</template>

<script>
import {api} from "Api";
import {capitalize} from "Helpers/helpers";
const ChangeProduct = () => import("Components/Appic/ChangeProduct");
const ExportTableJson = () => import("Components/Appic/ExportTableJson.vue");
const ProductPropertyPicker = () => import("Components/Appic/ProductPropertyPicker");
const ProductSelector = () => import("Components/Appic/ProductSelector")
const AddProductComments = () => import("Components/Appic/AddProductComments")

export default {
    name: "ProductTableList",
    components: {AddProductComments, ChangeProduct, ExportTableJson, ProductPropertyPicker, ProductSelector},
    props: ['supplierId'],
    data() {
        return {
            addCommentKey: 1000,
            allProducts: [],
            defaultGroupByProperty: 'Product.product',
            defaultProduct: {
                Product: {
                    composition: [],
                    density: [],
                    drying_type: [],
                    edge: [],
                    finishing: [],
                    glue: [],
                    grade: [],
                    grain: [],
                    id: 0,
                    length: [],
                    market_certification: [],
                    moisture_content: [],
                    origin_certification: [],
                    product: 'Decking',
                    profile: [],
                    sizes: [],
                    species: [],
                    surface: [],
                    thickness: [],
                    width: []
                }
            },
            groupByProperty: 'Product.product',
            loading: {
                products: false,
                reload: false
            },
            addComment: false,
            changeProduct: false,
            changeProductKey: 4000,
            productSelector: false,
            propertySelector: false,
            productSelectorKey: 2000,
            propertySelectorKey: 3000,
            selectedComment: null,
            selectedProperty: 'Product.product',
            selectedProductId: null,
            selectedProductGroup: null
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Product.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    groupable: false,
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.product'),
                    value: 'Product.product',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.species'),
                    value: 'Product.species',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.composition'),
                    value: 'Product.composition',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.grade'),
                    value: 'Product.grade',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.originCertification'),
                    value: 'Product.origin_certification',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.marketCertification'),
                    value: 'Product.market_certification',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.sizes'),
                    value: 'Product.sizes',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.thickness'),
                    value: 'Product.thickness',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.width'),
                    value: 'Product.width',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: this.$t('message.length'),
                    value: 'Product.length',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.drying'),
                    value: 'Product.drying_type',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.mc'),
                    value: 'Product.moisture_content',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.density'),
                    value: 'Product.density',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: this.$t('message.surfaceEdgesSides'),
                    value: 'Product.surface',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 15,
                    text: this.$t('message.glue'),
                    value: 'Product.glue',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 16,
                    text: this.$t('message.finishing'),
                    value: 'Product.finishing',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 17,
                    text: this.$t('message.comments'),
                    value: 'Product.comments',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                }
            ]
        }
    },
    methods: {
        addCommentClosed() {
            this.addComment = false
            return true
        },
        capitalize,
        changeProductClosed() {
            this.changeProduct = false
            this.selectedProductId = null
            this.selectedProductGroup = null
            return true
        },
        commentAdded() {
            this.addCommentKey += 1
            this.getProductList()
        },
        copyProduct(productToCopyId) {
            api
                .put('/products/' + productToCopyId + '/copy')
                .then((response) => {
                    if(response.data.status == 'success'){
                        this.getProductList()
                    } else {
                        this.$toast.error(this.$t('message.errors.productNotCopied'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.productNotCopied'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
            return true;
        },
        async deleteCommentsFromProduct (productId) {
            if(await this.$root.$confirm(this.$t('message.deleteComments'), this.$t('message.confirmations.continueCommentsCancelAction'), {color: 'orange'})){
                api
                    .delete('/products/' + productId + '/comments')
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.$toast.success(this.$t('message.successes.commentsDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.getProductList()
                        } else {
                            this.$toast.error(this.$t('message.errors.commentsNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.commentsNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
            return true
        },
        async deleteProduct(productId, product) {
            if(await this.$root.$confirm(this.$t('message.delete') + ': ' +  product, this.$t('message.confirmations.continueProductCancelAction'), {color: 'orange'})){
                api
                    .delete('/products/' + productId)
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.$toast.success(this.$t('message.successes.productDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.getProductList()
                        } else {
                            this.$toast.error(this.$t('message.errors.productNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.productNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
            return true;
        },
        async deletePropertyFromProduct(productId, product, propertyId) {
            if(await this.$root.$confirm(this.$t('message.deletePropertyFrom') + ': ' +  product, this.$t('message.confirmations.continuePropertyCancelAction'), {color: 'orange'})){
                api
                    .delete('/products/' + productId + '/properties/' + propertyId)
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.$toast.success(this.$t('message.successes.propertyDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.getProductList()
                        } else {
                            this.$toast.error(this.$t('message.errors.propertyNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.propertyNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
            return true;
        },
        getHeader(textValue) {
            let headerText = null
            const header = this.headers.find(header => {
                return header.value == textValue[0]
            })
            if(header) headerText = header.text
            return headerText
        },
        getProductList() {
            this.loading.products = true
            api
                .get('/products/suppliers/' + this.supplierId)
                .then((response) => {
                    if(response.data.status == 'success'){
                        this.allProducts = response.data.data
                        const retObj = {
                            supplierName: response.data.supplierName
                        }
                        this.$emit('list-loaded', retObj);
                    } else {
                        this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.products = false
                    if(this.loading.reload) this.loading.reload = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.products = false
                    if(this.loading.reload) this.loading.reload = false
                })
            return true;
        },
        getPropertyValue(property) {
            if(typeof property === 'string') {
                return property
            } else {
                if(typeof property == 'object' && typeof property[0] !== 'undefined') {
                    const value = property[0]['prop_value']
                    if (value) return value
                }
            }
            return 'na'
        },
        openAddComment(productId, productComment) {
            this.addComment = true
            this.selectedComment = productComment
            this.selectedProductId = productId
            return true;
        },
        openProductChanger(productId, productGroup) {
            this.changeProduct = true
            this.selectedProductId = productId
            this.selectedProductGroup = productGroup
            return true;
        },
        openProductSelector() {
            this.productSelector = true
            return true;
        },
        openPropertySelector(property, productId) {
            this.propertySelector = true
            this.selectedProductId = productId
            this.selectedProperty = property
            return true;
        },
        productAdded() {
            this.productSelectorKey += 1
            this.getProductList()
        },
        productSelectorClosed() {
            this.productSelector = false
            return true
        },
        productUpdated() {
            this.changeProductKey += 1
            this.getProductList()
        },
        propertyAdded() {
            this.propertySelectorKey += 1
            this.getProductList()
        },
        propertySelectorClosed() {
            this.propertySelector = false
            this.selectedProductId = null
            this.selectedProperty = 'Product.product'
            return true
        }
    },
    created() {
        if(this.allProducts.length == 0){
            this.getProductList()
        }
    }
}
</script>

<style scoped>
.v-data-table {
    overflow-x: auto;
}
.v-data-table::v-deep td.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep .v-row-group__header td{
    font-size: 12px !important;
}
.v-chip__content {
    font-size: 0.75rem !important;
}
</style>