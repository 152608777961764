var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"dense-inputs"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-btn',{on:{"click":function($event){return _vm.openProductSelector()}}},[_vm._v(_vm._s(_vm.$t('message.addProduct')))]),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","loading":_vm.loading.reload},on:{"click":function($event){_vm.getProductList(); _vm.loading.reload = true}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("refresh")])],1),_c('v-spacer'),_c('ExportTableJson',{staticClass:"float-right",staticStyle:{"padding-top":"0px !important"},attrs:{"export-conditions":{},"export-data":_vm.allProducts,"export-fields":_vm.headers,"export-source":'supplier-product-table'}})],1)]),_c('v-overlay',{attrs:{"value":_vm.loading.products,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.allProducts,"group-by":_vm.defaultGroupByProperty,"dense":"","hide-default-footer":"","show-group-by":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var groupBy = ref.groupBy;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus'))]),_vm._v(" "+_vm._s(_vm.getHeader(groupBy) + ': ' + _vm.getPropertyValue(group))+" ")],1)])]}},{key:"item.Product.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openProductChanger(item.Product.id, item.Product.product)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"black lighten-1"}},[_vm._v("mdi-swap-horizontal-variant")]),_vm._v(_vm._s(_vm.$t('message.changeProduct')))],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.copyProduct(item.Product.id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"black lighten-1"}},[_vm._v("mdi-content-copy")]),_vm._v(_vm._s(_vm.$t('message.copyProduct')))],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteProduct(item.Product.id, item.Product.product)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteProduct')))],1)],1)],1)],1)]}},{key:"item.Product.species",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.species),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.species && item.Product.species.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.species', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.species')))])])]:_vm._e()]}},{key:"item.Product.grade",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.grade),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.grade && item.Product.grade.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.grade', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.grade')))])])]:_vm._e()]}},{key:"item.Product.origin_certification",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.origin_certification),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.origin_certification && item.Product.origin_certification.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.origin_certification', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.certification')))])])]:_vm._e()]}},{key:"item.Product.market_certification",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.market_certification),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.market_certification && item.Product.market_certification.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.market_certification', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.certification')))])])]:_vm._e()]}},{key:"item.Product.sizes",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.sizes),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.size && item.Product.sizes.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.sizes', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.sizes')))])])]:_vm._e()]}},{key:"item.Product.thickness",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.thickness),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.thickness && item.Product.thickness.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.thickness', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.thickness')))])])]:_vm._e()]}},{key:"item.Product.width",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.width),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.width && item.Product.width.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.width', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.width')))])])]:_vm._e()]}},{key:"item.Product.length",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.length),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.length && item.Product.length.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.length', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.length')))])])]:_vm._e()]}},{key:"item.Product.drying_type",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.drying_type),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.drying_type && item.Product.drying_type.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.drying_type', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.dryingType')))])])]:_vm._e()]}},{key:"item.Product.moisture_content",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.moisture_content),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.moisture_content && item.Product.moisture_content.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.moisture_content', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.moistureContent')))])])]:_vm._e()]}},{key:"item.Product.density",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.density),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.density && item.Product.density.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.density', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.density')))])])]:_vm._e()]}},{key:"item.Product.surface",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.surface),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.surface && item.Product.surface.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.surface', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.surface')))])])]:_vm._e()]}},{key:"item.Product.composition",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.composition),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.composition && item.Product.composition.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.composition', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.composition')))])])]:_vm._e()]}},{key:"item.Product.glue",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.glue),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.glue && item.Product.glue.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.glue', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.glue')))])])]:_vm._e()]}},{key:"item.Product.finishing",fn:function(ref){
var item = ref.item;
return [_vm._l((item.Product.finishing),function(property){return [_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deletePropertyFromProduct(item.Product.id, item.Product.product, property.prop_id)}}},[_vm._v(_vm._s(property.prop_value))])]}),(item.Product.finishing && item.Product.finishing.length == 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openPropertySelector('Product.finishing', item.Product.id)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addProperty.finishing')))])])]:_vm._e()]}},{key:"item.Product.comments",fn:function(ref){
var item = ref.item;
return [(item.Product.comments != null)?[_c('v-chip',{attrs:{"close":"","close-icon":"mdi-close","small":""},on:{"click:close":function($event){return _vm.deleteCommentsFromProduct(item.Product.id)}}},[_vm._v(_vm._s(item.Product.comments))])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openAddComment(item.Product.id, item.Product.comments)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addComments')))])])]]}}])}),_c('AddProductComments',{key:_vm.addCommentKey,attrs:{"current-comment":_vm.selectedComment,"product-id":_vm.selectedProductId,"open-dialog":_vm.addComment},on:{"update:openDialog":function($event){_vm.addComment=$event},"update:open-dialog":function($event){_vm.addComment=$event},"property-added":_vm.commentAdded,"dialog-closed":_vm.addCommentClosed}}),_c('ProductPropertyPicker',{key:_vm.propertySelectorKey,attrs:{"property":_vm.selectedProperty,"product-id":_vm.selectedProductId,"open-dialog":_vm.propertySelector},on:{"update:openDialog":function($event){_vm.propertySelector=$event},"update:open-dialog":function($event){_vm.propertySelector=$event},"property-added":_vm.propertyAdded,"dialog-closed":_vm.propertySelectorClosed}}),_c('ProductSelector',{key:_vm.productSelectorKey,attrs:{"customer-id":null,"supplier-id":_vm.supplierId,"open-dialog":_vm.productSelector},on:{"update:openDialog":function($event){_vm.productSelector=$event},"update:open-dialog":function($event){_vm.productSelector=$event},"product-added":_vm.productAdded,"dialog-closed":_vm.productSelectorClosed}}),_c('ChangeProduct',{attrs:{"customer-id":null,"supplier-id":null,"product-id":_vm.selectedProductId,"current-product":_vm.selectedProductGroup,"open-dialog":_vm.changeProduct},on:{"update:openDialog":function($event){_vm.changeProduct=$event},"update:open-dialog":function($event){_vm.changeProduct=$event},"product-updated":_vm.productUpdated,"dialog-closed":_vm.changeProductClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }